import { Box, Card, CardContent, CardMedia, Grid, Stack, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React from 'react'
import WoodCard from '../woodCard/WoodCard';
import "./FeaturesCards.css"


const useStyles = makeStyles({
    card: {
        width: '100%',
    },
    media: {
        height: 0,
        paddingTop: '56.25%'
    },
});

function FeaturesCards() {
    const classes = useStyles();

    const data = [
        {
            image: "/assets/images/patternWood.svg",
            heading: "Dimensional Stability",
            details: "Swelling and shrinking of wood, due to moisture variation, is reduced by up to  60%. This dramatically improves stability and makes the wood lessprone to cup, warp or twist."
        },
        {
            image: "/assets/images/woodenTable.svg",
            heading: "High durability & decay resistant",
            details: "Modification densifies the wood, making it up to 33% harder. Vacuum/pressure impregnation (VPI) technology Wood is also more resistant to biological damages, such as decay caused by brown rot and funguses. This extends its service life."
        },
        {
            image: "/assets/images/dog.svg",
            heading: "Non-Toxic",
            details: "Th e Va c u u m / p re s s u re i m p re gn at i o n (VPI) technology,This green technology releases no odours or greenhouse gases. environmentally responsible choice , renewable,reusable, recyclable, and 100% biodegradable."
        },
        {
            image: "/assets/images/leaf.svg",
            heading: "Sustainability",
            details: "Our wood is sourced from renewable , sustainable FSC-certified forests, CocoWood and its customers are supporting sustainable development."
        },
        {
            image: "/assets/images/coffee.svg",
            heading: "Better insulating qualities",
            details: "Thermal conductivity is up to 25% lower than unmodified wood. This provides improved insulation performance, no hot feet on summer decks."
        },
        {
            image: "/assets/images/smoke.svg",
            heading: "Superior Fire Resistance",
            details: "Our wood is sourced from renewable , sustainable FSC-certified forests, CocoWood and its customers are supporting sustainable development."
        },
    ]
    return (
        <>
            <Stack sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block" } }}>


                <div className='features-section'>
                    <h1>BAIYIXIN  Modified Woods Advantages</h1>

                    {
                        data.map((item, index) => {
                            const isEven = index % 2 === 0;
                            return <Card className={classes.card} key={index} sx={{ mt: "100px", boxShadow: "none", borderRadius: "20px" }}>
                                <Grid container>
                                    {isEven ? (
                                        <>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <CardMedia image={item.image} className={classes.media} />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                    <CardContent sx={{ flex: '1 0 auto', alignItems: "center", ml: { xs: 0, md: 10 } }}>
                                                        <Stack sx={{ maxWidth: 500 }}>
                                                            <Typography sx={{
                                                                "fontStyle": "normal", "fontWeight": "700", "fontSize": "32px", "lineHeight": "30px", "letterSpacing": "3px", "textTransform": "capitalize", "color": "#6B5310",
                                                                mt: { xs: 0, md: 4 }
                                                            }}  >
                                                                {item.heading}
                                                            </Typography>
                                                            <Typography component="div" textAlign={"left"} sx={{
                                                                "fontStyle": "normal", "fontWeight": "500", "fontSize": "18px", "lineHeight": "25px", "letterSpacing": "3px", "color": "#333333", mt: 2
                                                            }}>
                                                                {item.details}
                                                            </Typography>
                                                        </Stack>
                                                    </CardContent>
                                                </Box>
                                            </Grid>
                                        </>
                                    ) : (
                                        <>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                    <CardContent sx={{ flex: '1 0 auto', alignItems: "center", ml: { xs: 0, md: 10 } }}>
                                                        <Stack sx={{ maxWidth: 500 }}>
                                                            <Typography sx={{
                                                                "fontStyle": "normal", "fontWeight": "700", "fontSize": "32px", "lineHeight": "30px", "letterSpacing": "3px", "textTransform": "capitalize", "color": "#6B5310",
                                                                mt: { xs: 0, md: 4 }
                                                            }}  >
                                                                {item.heading}
                                                            </Typography>
                                                            <Typography component="div" textAlign={"left"} sx={{
                                                                "fontStyle": "normal", "fontWeight": "500", "fontSize": "18px", "lineHeight": "25px", "letterSpacing": "3px", "color": "#333333", mt: 2
                                                            }}>
                                                                {item.details}
                                                            </Typography>
                                                        </Stack>
                                                    </CardContent>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <CardMedia image={item.image} className={classes.media} />
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </Card>
                        })
                    }
                </div>
            </Stack>
            {/* MOBILE */}
            <Stack sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none" } }} p={2}>
                <h1 style={{ "fontStyle": "normal", "fontWeight": "800", "fontSize": "22px", "lineHeight": "30px", "letterSpacing": "3px", "textTransform": "capitalize", "textAlign": "center", "color": "#6b5310", "margin": "60px 0" }}>BAIYIXIN  Modified Woods Advantages</h1>
                {
                    data.map((item, index) => {
                        return <Card className={classes.card} key={index} sx={{ mt: "100px", boxShadow: "none", borderRadius: "10px" }}>
                            <Grid container>
                                <>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <CardMedia image={item.image} className={classes.media} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <CardContent sx={{ flex: '1 0 auto', alignItems: "center", ml: { xs: 0, md: 10 } }}>
                                                <Stack >
                                                    <Typography sx={{
                                                        "fontStyle": "normal", "fontWeight": "700", "fontSize": "22px", "lineHeight": "30px", "letterSpacing": "3px", "textTransform": "capitalize", "color": "#6B5310",
                                                        mt: { xs: 0, md: 4 }
                                                    }}  >
                                                        {item.heading}
                                                    </Typography>
                                                    <Typography component="div" textAlign={"left"} sx={{
                                                        "fontStyle": "normal", "fontWeight": "500", "fontSize": "15px", "lineHeight": "25px", "letterSpacing": "3px", "color": "#333333", mt: 2
                                                    }}>
                                                        {item.details}
                                                    </Typography>
                                                </Stack>
                                            </CardContent>
                                        </Box>
                                    </Grid>
                                </>
                            </Grid>
                        </Card>
                    })
                }
            </Stack>
            <WoodCard />
        </>
    )
}

export default FeaturesCards