import React from 'react'
import styles from "./CasePage.module.css"
import Navbar from "../../components/header/Navbar"
import ContactBanner from '../../components/contactus/contactBanner/ContactBanner'
import CaseGallary from '../../components/caseGallary/CaseGallary'
import { Helmet } from 'react-helmet'
function CasePage() {
    return (
        <>
            <Helmet>
                <title>BAIYIXIN | Case</title>
            </Helmet>
            <div className={styles.case}>
                <Navbar />
                <ContactBanner path={"Home > Case"} heading={"Our Case"} />
            </div>
            <CaseGallary />
        </>
    )
}

export default CasePage