import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import ContactBanner from '../../components/contactus/contactBanner/ContactBanner'
import Navbar from '../../components/header/Navbar'
import FeaturesCards from '../../components/ourFeaturesCards/FeaturesCards'
import "./OurFeatures.css"
function OurFeatures() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <Helmet>
                <title>BAIYIXIN | Our Features</title>
            </Helmet>
            <div className='features'>
                <Navbar />
                <ContactBanner path={"Home > Features"} heading={"Our Features"} />
            </div>
            <FeaturesCards />

        </>
    )
}

export default OurFeatures