import React, { useEffect } from 'react'
import ContactBanner from '../../components/contactus/contactBanner/ContactBanner'
import Navbar from '../../components/header/Navbar'
import WoodCard from '../../components/woodCard/WoodCard'
import GetInTouch from '../../components/getInTouch/GetInTouch'
import "./OurProducts.css"
import { Helmet } from 'react-helmet'
function OurProducts() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <Helmet>
                <title>BAIYIXIN | Our Products</title>
            </Helmet>
            <div className='products'>
                <Navbar />
                <ContactBanner path={"Home > Products"} heading={"Our Products"} />
            </div>
            <WoodCard />
            <div className='products-table'></div>
            <GetInTouch />
        </>
    )
}

export default OurProducts