import { Stack } from '@mui/material'
import React from 'react'
import "./Aboutus.css"
function Aboutus() {
    return (
        <>
            <div className='about-us'>
                <h1>About <span>BAIYIXIN</span></h1>
                <div className='content'>
                    <p>
                        BAIYIXIN is an innovative company mainly manufactured impregnated modified wood based in China. It uses Vacuum/ pressure impregnation (VPI) modifications process, patented process that enhances the properties of sustainable softwood with a bio-based work liquid, the process permanently modifies the wood cell walls, let softwood own hardwood characteristics and rich colors.
                    </p>
                    <p>Due to its enhanced durability and stability, impregnated modified wood has emerged as a high performing, durable and sustainable alternative to conventional building materials, including traditional wood products.</p>
                    <p>Widely used in residential building material/ structural Framing, buildings weatherboard (cladding), outdoor decking, interior and exterior decoration, sauna, hardwood doors and windows, furniture, cabinets and other industries. We source raw materials from renewable, sustainable forests. We are proud to offer a product that is environmentally responsible.</p>
                </div>
            </div>

            <div className='about-us-details'>
                <h1>What is BAIYIXIN Modified Wood?</h1>
                <div className='content'>
                    <div className='image'>
                        <img src='/assets/images/Rectangle 1.png' />
                    </div>
                    <div className='data'>
                        <Stack sx={{ width: { xs: "300px", sm: "600px", md: "500px", lg: "600px" } }} spacing={4}>
                            <div>
                                <h1>Impregnation</h1>
                                <p>Impregnation is the process in which the wood is infused in bio-based liquid to make its dimension stable.</p>
                            </div>
                            <div>
                                <h1>Curing and Dying</h1>
                                <p>After impregnation, the wood is heated, also known as curing. The resulting polymer, which is now permanently locked into the wood cells, is stable and will not disintegrate or leach out of the wood.</p>
                            </div>
                            <div>
                                <h1>Results</h1>
                                <p>After modification, the wood cell walls are 50 percent thicker due to the stable, locked-in polymers. This permanent modification of the wood cell walls gives the final product outstanding stability, the maximum amount of hardness and long service life, also provides a high safety as the wood does not splinter and contains any toxins or chemicals, as well as staying cooler in summer.</p>
                            </div>
                        </Stack>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Aboutus