import { Facebook, Instagram, Twitter, YouTube } from '@mui/icons-material';
import { Divider, IconButton } from '@mui/material'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import "./footer.css"

function Footer() {
    const navigate = useNavigate();
    return (
        <>
            <div className='footer'>
                <div className='footer-items'>

                    <div className='location'>
                        <img style={{ width: "150px" }} src='/assets/images/Group 159.png' />

                        <div style={{ display: "flex" }}>
                            <img src='/assets/images/location.svg' />
                            <span>&nbsp;&nbsp;ShanghaiBaiyixin Wood <br /> &nbsp;&nbsp;Technology Co.LTD</span>
                        </div>

                        <div className='email'>
                            <img src='/assets/images/mail.svg' />
                            <span> &nbsp;charming@baiyixinwood.com</span>

                        </div>
                        <div className='social'>
                            <IconButton onClick={() => window.location.href = 'http://www.facebook.com'}>
                                <Facebook />
                            </IconButton>
                            <IconButton onClick={() => window.location.href = 'https://twitter.com'}>
                                <Twitter />
                            </IconButton>
                            <IconButton onClick={() => window.location.href = 'https://youtube.com'}>
                                <YouTube />
                            </IconButton>
                            <IconButton onClick={() => window.location.href = 'https://www.instagram.com'}>
                                <Instagram />
                            </IconButton>

                        </div>
                    </div>

                    <div className='company'>
                        <span>COMPANY</span>
                        <span onClick={() => navigate("/aboutus")}>About</span>
                        <span onClick={() => navigate("/our-products")}>Products</span>
                        <span onClick={() => navigate("/our-features")}>Features</span>
                    </div>
                    <div className='help'>
                        <span>HELP</span>
                        <span>Customer Support</span>
                        <span>Terms & Conditions</span>
                        <span>Privacy Policy</span>
                    </div>
                    <div className='newsletter'>
                        <span>NEWSLETTER</span>
                        <form>
                            <input type="text" placeholder='Enter your email address' />
                            <button>Subscribe Now</button>
                        </form>
                    </div>

                </div>
                <Divider />
                <div className='footer-copyright'>
                    <p>© Copyright 2022, All Rights Reserved by cjcocowoods</p>
                </div>
            </div>
        </>
    )
}

export default Footer