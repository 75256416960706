import React, { useEffect } from 'react'
import Navbar from "../../components/header/Navbar"
import Footer from "../../components/footer/Footer"
import "./ContactUs.css"
import ContactBanner from '../../components/contactus/contactBanner/ContactBanner'
import Details from '../../components/contactus/contactUsDetails/Details'
import { Helmet } from 'react-helmet'
function ContactUs() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <Helmet>
                <title>BAIYIXIN | Contact Us</title>
            </Helmet>
            <div className='contactus'>
                <Navbar />
                <ContactBanner heading={"Contact Us"} path={"Home > Contact Us  "} />
            </div>
            <Details />
        </>
    )
}

export default ContactUs