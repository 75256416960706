import React from 'react'
import "./ContactBanner.css"

function ContactBanner({ path, heading, details }) {
    return (
        <>
            <div className='contact-banner'>
                <div className='contact-banner-data'>
                    <p>{path}</p>
                    <h3>{heading}</h3>
                    <span>{details}</span>
                </div>
            </div>
        </>
    )
}

export default ContactBanner