import React from 'react'
import { useNavigate } from 'react-router-dom'
import "./GetInTouch.css"
function GetInTouch() {
    const navigate = useNavigate();
    return (
        <>
            <div className='getintouch'>
                <h1>Still have questions?</h1>
                <p>Can’t find the answer you’re looking for? Please connect to our friendly team.</p>
                <button onClick={() => navigate("/contactus")}>Get in touch</button>
            </div>
        </>
    )
}

export default GetInTouch