import { LocalPhone } from '@mui/icons-material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import "./Banner.css"

function Banner() {
    const navigate = useNavigate();
    return (
        <>
            <div className='banner  '>
                <div className='banner-data'>
                    <div className='images'>
                        <img src='/assets/images/photo_2023-01-18 13.45 4.png' />
                        <img src='/assets/images/name.svg' />
                    </div>
                    <h3>Exceptional Coverings & Finishes</h3>

                    <button onClick={() => navigate("/contactus")}><LocalPhone sx={{ fontSize: { xs: "15px", sm: "15px", md: "18px", lg: "18px" } }} />Contact Us</button>

                </div>
            </div>
        </>
    )
}

export default Banner