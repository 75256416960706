import './App.css';
import Footer from './components/footer/Footer';
import Router from './Router';
import { Routes, Route } from "react-router-dom";
import Homepage from './pages/homepage/Homepage';
import ContactUs from './pages/contactus/ContactUs';
import OurFeatures from './pages/ourFeatures/OurFeatures';
import OurProducts from './pages/ourProducts/OurProducts';
import AboutUs from './pages/aboutus/AboutUs';
import CasePage from './pages/casePage/CasePage';
function App() {
  return (
    <>
      <Routes>
        <Route path="/">
          <Route index element={<Homepage />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/our-features" element={<OurFeatures />} />
          <Route path="/our-products" element={<OurProducts />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/case" element={<CasePage />} />
        </Route>
      </Routes>
      <Footer />
    </>
  );
}

export default App;
