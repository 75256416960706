import { useEffect, useRef, useState } from 'react'
import { Close, Menu } from '@mui/icons-material'
import { Link, useLocation } from 'react-router-dom'


import "./Navbar.css"
function Navbar() {
    const navRef = useRef();
    const location = useLocation();
    const showNavBar = () => {
        navRef.current.classList.toggle("responsive_nav");
    }

    return (
        <>
            <header>
                <a href='/'>
                    <img src='/assets/images/Group 158.png' />
                </a>
                <button className="nav-btn" onClick={showNavBar}>
                    <Menu />
                </button>
                <nav ref={navRef}>

                    <Link to={"/"}>
                        <a className={location.pathname === '/' ? 'active' : ''} onClick={showNavBar}>Home</a>
                    </Link>
                    <Link to={"/aboutus"}>
                        <a className={location.pathname === '/aboutus' ? 'active' : ''} onClick={showNavBar}>About Us</a>
                    </Link>
                    <Link to={"/our-products"}>

                        <a className={location.pathname === '/our-products' ? 'active' : ''} onClick={showNavBar}>Our Products</a>
                    </Link>
                    <Link to={"/case"}>
                        <a className={location.pathname === '/case' ? 'active' : ''} onClick={showNavBar}>Case</a>
                    </Link>
                    <Link to={"/our-features"}>
                        <a className={location.pathname === '/our-features' ? 'active' : ''} onClick={showNavBar}>Our Features</a>
                    </Link>

                    <button
                        className="nav-btn nav-close-btn"
                        onClick={showNavBar}>
                        <Close />
                    </button>
                </nav>
            </header>
        </>
    )
}

export default Navbar