import React, { useEffect } from 'react'
import Navbar from "../../components/header/Navbar"
import ContactBanner from '../../components/contactus/contactBanner/ContactBanner'
import "./Aboutus.css"
import Aboutus from '../../components/about-baiyixin/Aboutus'
import GetInTouch from '../../components/getInTouch/GetInTouch'
import { Helmet } from 'react-helmet'
function AboutUs() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <Helmet>
                <title>BAIYIXIN | About Us</title>
            </Helmet>
            <div className='aboutus'>
                <Navbar />
                <ContactBanner details={"BAIYIXIN is an innovative company mainly manufactured impregnated modified wood based in China."} heading={"About BAIYIXIN"} path={"Home > About Us"} />
            </div>
            <Aboutus />
            <GetInTouch />
        </>
    )
}

export default AboutUs