import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import About from '../../components/about/About'
import Banner from '../../components/banner/Banner'
import Navbar from '../../components/header/Navbar'
import Process from '../../components/processOf/Process'

import "./Homepage.css"
function Homepage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <Helmet>
                <title>BAIYIXIN | Home</title>
            </Helmet>
            <div className='nav-banner'>
                <Navbar />
                <Banner />
            </div>
            <About />
            <Process />
        </>
    )
}

export default Homepage