import { Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material'
import React from 'react'
import "./WoodCard.css"

function WoodCard() {
    const data = [
        {
            image: "/assets/images/CjcocoWood from Charming-5 3.png",
            heading: "H3 Premium Cladding",
            width: "65mm/100mm/120mm",
            thickness: "10mm/15mm/18mm/20mm/30mm",
            length: "1.9M/3.9M"
        },
        {
            image: "/assets/images/CjcocoWood from Charming-5 4.png",
            heading: "H3 Premium Decking",
            width: "100/120mm ",
            thickness: "22mm",
            length: "1.9M/3.9M"
        },
        {
            image: "/assets/images/CjcocoWood from Charming-5 2.png",
            heading: "H3 Strucural",
            width: "100/120mm",
            thickness: "22mm",
            length: "1.9M/3.9M",
            style: "82px"
        },
    ]
    return (
        <>
            <div className='wood-card-head'>
                <h1>BAIYIXIN Modified Woods</h1>
            </div>
            <div className='wood-cards'>
                {
                    data.map((item) => (
                        <div className='card'>
                            <img src={item.image} style={{ width: `${item.style}` }} />
                            <div className='card-content'>
                                <h1>{item.heading}</h1>
                                <div className='card-details'>
                                    <p>Width : {item.width}</p>
                                    <p>Thickness : {item.thickness}</p>
                                    <p>Length : {item.length}</p>
                                </div>
                                <button>View Details</button>
                            </div>
                        </div>
                    ))
                }
            </div>
        </>
    )
}

export default WoodCard