import { Grid, Stack } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import "./Process.css"

function Process() {
    const navigate = useNavigate();
    const cards = [
        {
            image: "/assets/images/dimensions 1.png",
            head: "Dimensional Stability"
        },
        {
            image: "/assets/images/flameproof-fabric 1.png",
            head: "Superior Fire Resistance"
        },
        {
            image: "/assets/images/non-toxic 1.png",
            head: "Non - Toxic"
        },
        {
            image: "/assets/images/Group 41 (1).png",
            head: "Better Insulating Qualities"
        },
        {
            image: "/assets/images/Group 42.png",
            head: "High Durability & Decay Resistant"
        },
        {
            image: "/assets/images/planet-earth 1.png",
            head: "Sustainability"
        },
    ]
    return (
        <>
            <div className='process-section'>
                <div className='process-header'>
                    <h1>Process of <span style={{ "fontWeight": "800", "fontSize": "36px", "lineHeight": "30px", "letterSpacing": "3px", "textTransform": "capitalize", "color": "#644A3E" }}>BAIYIXIN</span> Woods</h1>
                    <h4>Developed in China, baiyixinWood is an environmentally friendly. Two basic steps
                    </h4>
                </div>
                <div className='process-details'>
                    <div className='left'>
                        <Stack sx={{ width: { xs: "340px", sm: "600px", md: "500px", lg: "600px" } }} spacing={2}>
                            <div className='img'>
                                <img src='/assets/images/1.svg' />
                                <span>1</span>
                            </div>
                            <h5>Impregnation</h5>
                            <p>Impregnation is the process in which the wood is infused produced from a bio-based liquid to make dimensionally stable.
                            </p>
                        </Stack>
                    </div>
                    <div className='right'>
                        <Stack sx={{ width: { xs: "340px", sm: "600px", md: "500px", lg: "600px" } }} spacing={2}>

                            <div className='img'>
                                <img src='/assets/images/1.svg' />
                                <span>2</span>
                            </div>
                            <h5>Curing and Drying</h5>
                            <p>After impregnation, the wood is heated, also known  as curing. The resulting polymer, which is now permanently locked into the wood cells, is stable and will not disintegrate or leach out of the wood.
                            </p>
                        </Stack>
                    </div>

                </div>
                <div className='image'>
                    <img src="/assets/images/CjcocoWood from Charming-444 1.png" />
                </div>

                <div className='result'>
                    <div className='result-content'>
                        <div className='image'>
                            <img src='/assets/images/1.svg' />
                            <span>3</span>
                        </div>
                        <h1 >Results</h1>
                        <div className='content'>
                            <p>
                                After modification, the wood cell walls are 50 percent thicker due to the stable, locked-in polymers. This permanent modification of the wood cell walls gives the final wood product outstanding stability, the maximum amount of hardness and a guaranteed long life. CjcocoWood also provides a high level of safety as the wood does not splinter and contains no toxins or chemicals, as well as staying cooler in the summer.
                            </p>

                        </div>
                    </div>
                    <div className='result-image'>
                        <img src='/assets/images/results.svg' />
                    </div>
                </div>
                <Stack sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}>

                    <div className='process-advantages'>
                        <div className='head'>
                            <h1>BAIYIXIN</h1>
                            <h2>Modified Woods Advantages</h2>
                        </div>
                        <div onClick={() => navigate("/our-features")} style={{ cursor: "pointer" }}>
                            <img src='/assets/images/group.svg' />
                        </div>
                    </div>
                </Stack>

                {/* MOBILE */}
                <Stack sx={{ display: { lg: "none", md: "block", sm: "block", xs: "block" } }}>
                    <div className='adv-sec'>
                        <div className='header'>
                            <h1>CjCocoWoods</h1>
                            <h2>Modified Woods Advantages</h2>
                        </div>

                        <div className='cards'>
                            <Grid container spacing={2} >
                                {
                                    cards.map((card) => (
                                        <Grid item xs={6} sm={4} sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "0px auto" }}>
                                            <Stack spacing={1.5} direction={"column"} alignItems="center" justifyContent={"center"} sx={{ "width": "150px", "height": "143.81px", "background": "rgba(0, 0, 0, 0.4)", "border": "0.449405px solid #FFFFFF" }}>
                                                <img src={card.image} style={{ width: "61.99px" }} />
                                                <h2 style={{ "fontStyle": "normal", "fontWeight": "600", "fontSize": "12px", "lineHeight": "13px", "textAlign": "center", "color": "#FFFFFF" }}>{card.head}</h2>
                                            </Stack>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </div>
                    </div>
                </Stack>
            </div>
        </>
    )
}

export default Process