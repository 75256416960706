import { LocationOn, Mail } from '@mui/icons-material'
import React from 'react'
import "./Details.css"
function Details() {
    return (
        <>
            <div className='contactus-section'>
                <div className='logo'>
                    <img src='/assets/images/contactuslogo.svg' />
                </div>
                <div className='location'>
                    <span>Shanghai Baiyixin Wood Technology Co.LTD</span>
                    <div className='loc'>
                        <div style={{ display: "flex", alignItems: "center", color: "#D64627", gap: "10px" }}>
                            <LocationOn />
                            <p>Jinhai Road 6055#, Fengxian District, Shanghai, China</p>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", color: "#D64627", gap: "10px" }}>
                            <Mail />
                            <p>charming@baiyixinwood.com</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='contact-form'>
                <div className='head'>
                    <h1>keep in touch</h1>
                    <h2>Call Us or fill the form</h2>
                </div>

                <form>
                    <div>
                        <label>Name</label>
                        <input type={"text"} />
                    </div>
                    <div>
                        <label>Company</label>
                        <input type={"text"} />
                    </div>
                    <div>
                        <label>Email</label>
                        <input type={"email"} />
                    </div>
                    <div>
                        <label>Phone</label>
                        <input type={"number"} />
                    </div>
                    <div>
                        <label>Message</label>
                        <textarea />
                    </div>
                    <button type='submit'>Submit</button>
                </form>
            </div>
        </>
    )
}

export default Details