import { Box, ImageList, ImageListItem, Stack } from '@mui/material';
import React from 'react'
import styles from "./CaseGallary.module.css"
function CaseGallary() {
    const itemData = [
        {
            img: '/assets/gallery/severin-stalder-2j75u62e6Ok-unsplash 1.png',
        },
        {
            img: '/assets/gallery/kenny-kennethh-FAKisvTCvYs-unsplash 1.png',
        },
        {
            img: '/assets/gallery/jovana-jankovic-QF1mVESDPx8-unsplash 1.png',
        },
        {
            img: '/assets/gallery/olga-kudriavtseva-bsm95Fs0inc-unsplash 1.png',
        },
        {
            img: '/assets/gallery/alex-gutierrez-ry6P4wsK2-4-unsplash 1.png',
        },
        {
            img: '/assets/gallery/jack-b-gTDz6xsqT70-unsplash 1.png',
        },
        {
            img: '/assets/gallery/ryunosuke-kikuno-akIQHnX3qJQ-unsplash 1.png',
        },
        {
            img: 'assets/gallery/aaron-vazquez-jqUZdqNcyHM-unsplash 1.png',
        },
        {
            img: '/assets/gallery/charlie-gregory-M_QxpLHLfJM-unsplash 1.png',
        },
        {
            img: '/assets/gallery/matt-lillywhite-JuaB5xKkYWE-unsplash 1.png',
        },
        {
            img: '/assets/gallery/rumman-amin-tlqjYD_1ZyE-unsplash 1.png',
        },
        {
            img: '/assets/gallery/roberto-nickson-wRF6hCur9A8-unsplash 1.png',
        },

    ];
    return (
        <>
            <Stack alignItems={"center"} justifyContent="center" py={10} spacing={6}>
                <h1 className={styles.h1}>Case</h1>
                <Box sx={{ width: "80%" }}>
                    <ImageList variant="masonry" cols={3} gap={8}>
                        {itemData.map((item) => (
                            <ImageListItem key={item.img}>
                                <img
                                    src={item.img}
                                    srcSet={item.img}
                                    alt="wood gallery"
                                    loading="lazy"
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Box>
            </Stack>
        </>
    )
}

export default CaseGallary