import { ArrowForward } from '@mui/icons-material'
import { Stack } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import "./About.css"
function About() {
    const navigate = useNavigate()
    return (
        <>
            <div className='about'>
                <div className='about-content'>
                    <h1 >About <span style={{ "fontStyle": "normal", "fontWeight": "700", "fontSize": "38px", "lineHeight": "30px", "letterSpacing": "3px", "textTransform": "capitalize", }}>BAIYIXIN</span></h1>
                    <div className='content'>
                        <p>
                            Over the last few decades, building design and construction has changed in profound ways. New systems, technologies and materials are revolutionizing new space construction. Innovation is everywhere in the modern, built environment.
                        </p>
                        <p>
                            Contemporary designers and developers embrace innovation. It’s a way for them to create spaces that are beautiful, functional and sustainable.
                        </p>
                    </div>
                    <button onClick={() => navigate("aboutus")}>Read More <ArrowForward /></button>
                </div>
                <div className='about-image'>
                    <img src='/assets/images/Rectangle 1.png' />
                </div>
            </div>
        </>
    )
}

export default About